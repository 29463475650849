<template>
    <div>
  <div>
    <Navbar />
   <ContainerView />
    
  </div>
    </div>
</template>

<script>
import Navbar from "../components/navbar/Navbar-comp.vue";
import ContainerView from "../components/main/Main-comp.vue";

export default {
 components: {
    Navbar,
    ContainerView,    
  },
}
</script>

<style>

</style>